<template>
	<div>
		<div
			class="jumbotron jumbotron-home has-image"
			v-if="!!post"
		>
			<div class="container">
				<h1>{{post.title}}</h1>
				<div class="lead">
					<router-link to="/blog">&Larr; Back to Posts</router-link>
				</div>
			</div>
		</div>
		<Section>
			<div slot="body">
				<div class="mb-3 font-italic">{{getFormattedDate(post.date)}}</div>
				<p class="blog-body" v-html="post.body" />
			</div>
		</Section>
	</div>
</template>
<script>
import BlogMixin from '@/components/mixins/BlogMixin'
import Section from './Section'
export default {
	components: {
		Section
	},
	computed: {
		id () {
			return String(this.$route.params.id)
		},
		post () {
			let post = null
			if (this.id && this.posts && this.posts.length) {
				post = this.posts.find(post => String(post.id) === this.id)
			}
			return post
		}
	},
	created () {
		this.posts = this.getPosts()     
	},
	mixins: [
		BlogMixin
	]
}
</script>
<style lang="scss">
.blog-body{
	margin-top: 3rem;
	&::first-letter{
		font-size: 3rem;
		font-weight: bold;
	}
	&:after{
		content: '\000d74';
		display: inline-block;
		margin-left: 0.5rem;
	}
}
</style>