import moment from 'moment'
import blogUtils from '@/blog/blogUtils'

const BlogMixin = {
    data () {
		return {
			posts: []
		}
	},
    methods: {
		getFormattedDate(date) {
			return moment(date).format('MMMM Do, YYYY')
        },
        getPosts () {
            return blogUtils.getBlogPosts()
        }
	}
}

export default BlogMixin