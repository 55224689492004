<template>
    <div>
        <p>
            Please  contact Jessie Frances by<a href="mailto:JFrancesAcu@gmail.com" target="_blank"> email</a> or <a :href="`tel:${PHONE}`" target="_blank">phone</a> to answer any questions you have or book an appointment
        </p>
        <p>
            We do not accept insurance at this time
        </p>
        <!-- <div>
            <a href="https://portal.holbie.com/form/QPZ/2HC" target="_blank" class="btn btn-primary">Verify Insurance</a>
        </div> -->
    </div>
</template>
<script>
import constants from '@/constants'
export default {
    data () {
        return {
            PHONE: constants.PHONE
        }
    }
}
</script>