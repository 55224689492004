<template>
    <section class="section">
        <div class="container">
            <div class="card">
                <header class="card-header" v-if="title">
                    <h3 class="card-title h5 my-0">{{title}}</h3>
                </header>
                <slot name="body" />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        title: {
            type: String
        }
    }
}
</script>