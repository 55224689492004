<template>
	<div>
		<div class="jumbotron jumbotron-services has-image">
			<div class="container">
				<h1>{{$t('sections.services')}}</h1>
				<p class="lead">{{$t('services.lead')}}</p>
			</div>
		</div>
		<Section :title="$t('services.rates')">
			<ul class="list-group list-group-flush" slot="body">
				<dl class="list-group-item pl-0">
					<dt>Initial visit (includes consultation, exam and acupuncture treatment)</dt>
					<dd class="h5">
						$150/75 min
					</dd>
				</dl>
				<dl class="list-group-item pl-0">
					<dt>Return visit</dt>
					<dd class="h5">
						$100/60 min
						<small>*Packages available</small>
					</dd>
				</dl>
				<dl class="list-group-item pl-0">
					<dt>Herbal Medicine consultation</dt>
					<dd class="h5">$75</dd>
				</dl>
			</ul>
			<book-now-button
				class="mb-5"
				slot="body" />
			<div class="card-body" slot="body">
				Note: Depending on what the practitioner sees as needed other modalities such as cupping, guasha, moxibustion will be used
			</div>
		</Section>
		<Section :title="$t('services.cancellation')">
			<div class="card-body" slot="body">
				<p>{{$t('services.cancellationPolicy')}}</p>
			</div>
		</Section>
	</div>
</template>

<script>
import Section from './Section'
import BookNowButton from './BookNowButton'

export default {
	components: {
		BookNowButton,
		Section
	}
}
</script>