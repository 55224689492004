<template>
	<footer class="bg-warning py-5">
		<div class="container text-right">
			<div>
				<div class="h5 mb-3">Jessie Frances <strong>Acupuncture</strong></div>
				<address>
					<div>Hyde Park, Boise, CO</div>
					<a :href="`tel:${PHONE}`" target="_blank" class="text-dark">{{PHONE_DISPLAY}}</a><br />
					<a href="mailto:JFrancesAcu@gmail.com" target="_blank" class="text-dark">JFrancesAcu@gmail.com</a>
				</address>
			</div>			
		</div>
	</footer>
</template>
<script>
import constants from '@/constants'
export default {
    data () {
        return {
			PHONE: constants.PHONE,
			PHONE_DISPLAY: constants.PHONE_DISPLAY
        }
    }
}
</script>