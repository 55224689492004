<template>
	<div>
		<div class="jumbotron jumbotron-first has-image">
			<div class="container">
				<h1>First-Time Patients</h1>
			</div>
		</div>
		<Section :title="$t('patients.whatToExpect')">
			<div class="card-body" slot="body">
				<p>
					{{$t('patients.whatToExpectContent')}}
				</p>
			</div>
		</Section>
		<Section :title="$t('patients.howToPrepare')">
			<div class="card-body" slot="body">
				<p>
					{{$t('patients.howToPrepareContent')}}
				</p>
			</div>
		</Section>
	</div>
</template>
<script>
import Section from './Section'

export default {
	components: {
		Section
	}
}
</script>