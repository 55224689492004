<template>
	<div>
		<button
			class="btn btn-warning"
			@click="shouldShowModal = !shouldShowModal"
		>
			{{$t('bookNow')}}
		</button>
		<b-modal
			v-model="shouldShowModal"
			size="lg"
			:hide-backdrop="true"
			:title="$t('bookNow')"
		>
			<BookNow />
		</b-modal>
	</div>
</template>
<script>
import BookNow from '@/components/BookNow'
import { BModal } from 'bootstrap-vue'

export default {
	components: {
		BookNow,
		BModal
	},
	data () {
		return {
			shouldShowModal: false
		}
	}
}
</script>