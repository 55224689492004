export default {
	acupuncture: 'Acupuncture',
	bookNow: 'Book Now',
	sections: {
		home: 'Home',
		services: 'Services',
		about: 'About',
		faq: 'FAQ',
		patients: 'First Time Patients',
		blog: 'Blog'
	},
	home: {
		heading: 'Japanese Acupuncture in Boise, ID',
		lead: 'Symptoms are the body’s way of telling you that something is out of balance. Acupuncture works to encourage the body to come back into its own balance so it can heal. Below are some of the conditions that we treat:',
		lead2: 'Serving patients in the Treasure Valley',
		issues: 'What we treat',
		issues1: 'Headaches, Sleep issues, Depression/Anxiety/mood swings',
		issues2: 'Dry Eyes, Tinnitus',
		issues3: 'Frequent colds, Common Cold',
		issues4: 'Food Cravings, Gallstones, Digestive Disorders',
		issues5: 'Muscle Pain, Fatigue, Injury Rehabilitation, Shoulder, Hip, Knee Lower back issues, Joint issues',
		issues6: 'Stroke Rehabilitation, Bell’s Palsy Balance Issues',
		practitioner: 'Practitioner',
		covidTitle: 'COVID 19 Pandemic Precautions',
		covidBody: 'Due to the ever evolving state of the pandemic we ask that you still wear a mask over your nose and mouth during your appointment to protect other high risk patients from the COVID-19 virus.'
	},
	about: {
		chineseMed: 'From as early as the 16th century BCE Acupuncture and herbal formulas were used to treat a variety of illnesses. The study of Chinese Medicine is a complete system of medicine which includes extensive texts to treat most conditions. This system provides a different understanding of health. We are so fortunate to have modern day healthcare, but sometimes we need to take a different approach.',
		chineseMed2: 'The five branches of Oriental medicine are equally important to look at when examining our health: Acupuncture, Herbs, Diet, Exercise and meditation. Ask us more about how we can help with your health needs.',
		practitioner: 'Jessie Frances L.Ac is a licensed Acupuncturist in the State of Idaho with a Masters Degree in Chinese Medicine and a Certificate in Western Herbalism. Her specialization is working with women. Her treatments use a gentle Japanese needling technique which emphasizes subtlety over intensity when treating patients. Jessie has over 3,000 hours of training including needling, Chinese Herbal Medicine, fire cupping, moxibustion, guasha, Shiatsu, and Tuina. She also has training in Craniosacral Therapy and Western Herbal Medicine. She has a Bachelors degree in Eastern Religion. She also has a strong interest in interfaith Hospice work and has been a long time practitioner of meditation.'
	},
	patients: {
		whatToExpect: 'What to expect',
		whatToExpectContent: 'The first appointment will be an extensive intake including what medications and supplements you are taking. Subsequent questioning will not take as long. With acupuncture insertion, you may feel a heavy, dull, achy or throbbing sensation when the needle is first inserted. This is normal. The pain should subside, but if it doesn’t please let us know and we will remove the needle. If you are prescribed herbs you will be instructed how often to take them and when.',
		howToPrepare: 'How to prepare for your first treatment',
		howToPrepareContent: 'Please wear comfortable loose fitting clothing. If you forget, we have sheets available. Eat a light meal or a snack before coming. Answer the interview questions honestly and completely- there are questions you may not be accustomed to answering but to get the best picture of your health, we want to know about them!'
	},
	services: {
		rates: 'Rates and Services',
		lead: 'Offering acupuncture, Chinese herbal medicine, cupping, moxibustion and shiatsu treatments.',
		cancellation: 'Cancellation Policy',
		cancellationPolicy: 'If you need to cancel or reschedule or appointment, a 24 hour notice is required. Missed or cancelled appointments with less than 24 hours notice will be charged the full fee of the appointment.',
		insurance: 'We accept insurance',
		mobile: 'Services',
		mobileServices: 'Services are currently offered.'
	},
	faq: [
		{
			q: 'Does acupuncture hurt?',
			a: 'Acupuncture needles are different from regular needles in that they are solid (filiform) needles that are much thinner. The practitioner will do everything she can to ensure you are comfortable. If something hurts during the treatment, we will adjust and remove the needle if need be. Minor bruising can occur which is not painful and should subside within a couple of days.'
		},
		{
			q: 'Do you take insurance?',
			a: 'Not at this time'
		},
		{
			q: 'What does acupuncture treat? How long will I need treatment for?',
			a: 'Acupuncture treats many health issue when combined with Chinese Herbs but some conditions can be more difficult to treat and have less success, or take longer for a successful outcome. Treatments could be anywhere from 1 month to much longer to see lasting effects but you should see some shift towards improvement within a week if you are using herbs and acupuncture. If not, please let us know. Also, a major part of the medicine is bringing awareness to how you take care of yourself through diet, appropriate exercise, and rest.'
		},
		{
			q: 'Do you use animal products in your herbal formulas?',
			a: 'Yes we do, but we do not use endangered animals or plants. If you are vegetarian or vegan or do not feel comfortable with animal products please let us know before we prepare your herbs.'
		},
		{
			q: 'Are your herbal formulas ethically sourced, free from fillers, tested for heavy metals?',
			a: 'Yes, the companies that we use high quality testing to ensure safety, purity and consistency. These include chemical, heavy metal, aristolochic acid and microbiologic testing. The herbal companies we use are also ethically sourced and do not use endangered species.'
		},
		{
			q: 'Do acupuncture needles spread disease?',
			a: 'No. The Council of Colleges of Acupuncture and Oriental Medicine’s (CCAOM) Clean Needle Technique (CNT) is required for National Certification Commission for Acupuncture and Oriental Medicine (NCCAOM) certification. We are required by Occupational Safety and Health Administration (OSHA) to follow the standards in using sterilization procedures for the protection against the spread of viruses and infectious diseases. All needles are disposable single use.'
		},
		{
			q: 'I want to receive the benefits of acupuncture but I am needle sensitive and can’t handle needles. Do you do other therapies?',
			a: 'Many people benefit from Acupressure or Acutonics, the use of tuning forks on the acupuncture points. At this time we do not use tuning forks but we are happy to refer you to someone who does. Also, specific to your health, Chinese herbs alone may be appropriate.'
		}
	],
	language: {
		en: 'English',
		es: 'Español'
	}
}