export default {
    acupuncture: 'Acupuntura',
    sections: {
        home: 'Casa',
        services: 'Servecios',
        about: 'Sobre',
        faq: 'Preguntas',
        patients: 'Pacientes'
    },
    language: {
		en: 'English',
		es: 'Español'
	}
}
