<template>
	<div>
		<div class="jumbotron jumbotron-home has-image">
			<div class="container">
				<h1>{{$t('home.heading')}}</h1>
				<p class="lead">{{$t('home.lead2')}}</p>
			</div>
		</div>
		<Section :title="$t('home.issues')">
			<div class="card-body" slot="body">
				<p class="lead">{{$t('home.lead')}}</p>
			</div>
			<div slot="body">
				<div class="row row-cols-auto">
					<Treatments
						class="col-md-4"
						name="Sleep Issues"
						:items="['Insomnia', 'Nightsweats', 'Pain']" />
					<Treatments
						class="col-md-4"
						name="Mental Health"
						:items="['Depression', 'Anxiety']" />
					<Treatments
						class="col-md-4"
						name="Immune System"
						:items="['Frequent colds', 'Common Cold']" />
					<Treatments
						class="col-md-4"
						name="Digestive Disorders"
						:items="['Low or high appetite', 'Digestive discomfort', 'Gallstones', 'Nausea']" />
					<Treatments
						class="col-md-4"
						name="Women’s Health"
						:items="['Menstrual Disorders', 'Infertility', 'Menopause']" />
					<Treatments
						class="col-md-4"
						name="Musculoskeletal Issues"
						:items="['Low Back Pain', 'Hip Pain', 'Shoulder pain', 'Knee pain', 'Injury Rehabilitation']" />
					<Treatments
						class="col-md-4"
						name="Neurological Issues"
						:items="['Stroke Rehabilitation', 'Bell\'s Palsy', 'Balance Issues']" />
					<Treatments
						class="col-md-4"
						name="Cancer medication Side Effects"
						:items="['Chemotherapy induced Neuropathy', 'Nausea', 'Insomnia', 'Medication induced hot flashes', 'stress', 'anxiety']"
					/>
					<Treatments
						class="col-md-4"
						name="Others"
						:items="['Dry Eyes', 'Tinnitus', 'Headaches', 'Autoimmune Disorders']" />
					
				</div>
			</div>
			<BookNowButton class="mt-3" slot="body" />
		</Section>
		<Section title="Chinese Medicine">
			<p class="card-body" slot="body" v-html="$t('about.chineseMed')" />
			<p class="card-body" slot="body" v-html="$t('about.chineseMed2')" />
		</Section>
		<Section :title="$t('home.practitioner')">
			<div slot="body">
				<img
					:src="require('../../public/jessie.jpg')"
					alt=""
					class="thumbnail">
				<p>{{$t('about.practitioner')}}</p>
			</div>
		</Section>
		<Section title="Insurance">
			<div slot="body">
				<p>We do not accept insurance at this time</p>
			</div>
		</Section>
		<Section>
			<p slot="body">We are dedicated to learning more about what inclusion means. We welcome all people to receive this medicine. We support Black Lives and the LGBTQ population. We are also educating ourselves more about what it means to support these communities. Please let us know how we can be better too!</p>
		</Section>
		<Section title="Location">
			<div slot="body" class="text-right">
				<div class="mapouter">
					<div class="gmap_canvas">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.8674754295034!2d-116.20604582364538!3d43.63011715392319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54aef926fad417e9%3A0x9d7f1bedc9075459!2sHyde%20Park!5e0!3m2!1sen!2sus!4v1725032085784!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</div>
		</Section>
	</div>
</template>
<script>
import Section from './Section'
import BookNowButton from './BookNowButton'
import Treatments from './Treatments'

export default {
	components: {
		BookNowButton,
		Section,
		Treatments
	}
}
</script>
