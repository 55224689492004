<template>
	<div>
		<div class="jumbotron jumbotron-faq has-image">
			<div class="container">
				<h1>Frequently Asked Questions</h1>
			</div>
		</div>
		<Section>
			<dl
				class="list-group"
				v-for="(question, key) in questions"
				v-bind:key="key"
				slot="body"
			>
				<dt class="list-group-item h4 text-primary">{{$t(`faq.${key}.q`)}}</dt>
				<dd class="list-group-item text-dark" v-html="$t(`faq.${key}.a`)" />
			</dl>
		</Section>
	</div>
</template>

<script>
import content from '@/localization/english'
import Section from './Section'
export default {
	components: {
		Section
	},
	data () {
		return {
			questions: content.faq
		}
	}
}
</script>