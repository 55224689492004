<template>
	<div class="mb-3">
		<div class="card card-treatment">
			<div class="card-header">
				<h4 class="card-title">{{name}}</h4>
			</div>
			<ul class="card-body">
				<li
					v-for="(item, index) in items"
					v-bind:key="index"
					>
					{{item}}
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		name: {
			type: String
		},
		items: {
			type: Array,
			default: () => []
		}
	}
}
</script>