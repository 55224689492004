<template>
    <b-dropdown
        id="dropdown-locale"
        :text="localeDisplay"
        variant="link"
        right
        >
        <b-dropdown-item
            v-for="[id, locale] in Object.entries(locales)"
            v-bind:key="id"
            :active="id === localeId"
            @click="setLocale(id)"
            >
            {{$t(locale.display)}}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
    components: {
        BDropdown,
        BDropdownItem
    },
    computed: {
        localeId () {
            return this._i18n.locale
        },
        localeDisplay () {
            return this.$t(this.locales[this.localeId].display)
        }
    },
    data () {
        return {
            locales: {
                en: {
                    display: 'language.en',
                    code: 'en_US'
                },
                es: {
                    display: 'language.es',
                    code: 'es-MX'
                }
            }
        }
    },
    methods: {
        setLocale (locale) {
            this._i18n.locale = locale
            document.getElementsByTagName('html')[0].setAttribute('lang', locale)
        }
    }
}
</script>