import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import routes from './routes'
import VueI18n from 'vue-i18n'
import localeEn from '@/localization/english'
import localeEs from '@/localization/spanish'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueI18n)

const router = new VueRouter({
	routes,
	mode: 'history'
})

const i18n = new VueI18n({
	locale: 'en',
	messages: {
		en: localeEn,
		es: localeEs
	}
})

new Vue({
	i18n,
	render: h => h(App),
	router
}).$mount('#app')
