const getBlogPosts = function() {
    const posts = []
    const postFiles = require.context(
        '@/assets/blog_posts',
        true,
        /^.*\.json$/
    )
    postFiles.keys().forEach(post => {
        post = post.replace(/.\//, '/')
        posts.push(require(`@/assets/blog_posts${post}`))
    })
    return posts
}

export default {
    getBlogPosts
}