import Home from '@/components/Home'
import Services from '@/components/Services'
// import About from '@/components/About'
import Faq from '@/components/Faq'
import FirstTimePatients from '@/components/FirstTimePatients'
// import Blog from '@/components/Blog'
import BlogPost from '@/components/BlogPost'

export default  [
    { path: '/', component: Home, display: 'sections.home', name: 'Home' },
    { path: '/services', component: Services, display: 'sections.services', name: 'Services' },
    // { path: '/about', component: About, display: 'sections.about', name: 'About' },
    { path: '/questions-and-answers', component: Faq, display: 'sections.faq', name: 'Faq' },
    { path: '/first-time-patients', component: FirstTimePatients, display: 'sections.patients', name: 'Patients' },
    // { path: '/blog', component: Blog, display: 'sections.blog', name: 'Blog' },
    { path: '/blog/post/:id', component: BlogPost, name: 'Blog Post' },
]